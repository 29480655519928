import type { FC, ReactNode } from "react"
import PropTypes from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { Logo } from "src/components/logo"
import { RouterLink } from "src/components/router-link"
import { paths } from "src/paths"

interface LayoutProps {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = props => {
  const { children } = props

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "neutral.250",
          backgroundImage:
            'url("https://getpresto.app/images/assets/ils_11.png")',
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography sx={{ mb: 1, color: "text.primary" }} variant="h4">
            Bem Vindo a Presto App
          </Typography>
          <Typography color="primary.main" variant="h6" sx={{ mb: 4 }}>
            <br />
            Use o seu tempo para você, enquanto nós cuidamos do seu dia a dia.
          </Typography>
          <Typography variant="h5" sx={{ mb: 3, color: "text.primary" }}>
            Comodidade e
            <Typography component="span" color="primary.main" variant="inherit">
              &nbsp; hospitalidade
            </Typography>
            &nbsp; na sua casa
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: "text.primary",
              "& > *": {
                color: "neutral.400",
                flex: "0 0 auto",
              },
            }}
          >
            {/* <LogoSamsung />
            <LogoVisma />
            <LogoBolt />
            <LogoAws />
            <LogoAccenture />
            <LogoAtt /> */}
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box sx={{ mb: 4 }}>
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 24,
                  width: 24,
                }}
              >
                <Logo />
              </Box>
              <Box
                sx={{
                  color: "text.primary",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: "0.3px",
                  lineHeight: 2.5,
                  "& span": {
                    color: "primary.main",
                  },
                }}
              >
                Presto<span> APP </span>
              </Box>
            </Stack>
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}
