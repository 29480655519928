import { useCallback, useEffect, useState, type FC } from 'react';
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users03';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { CustomersPopover } from './customers-popover';
import { customersApi } from 'src/api/customers';
import { useMounted } from 'src/hooks/use-mounted';
import type {
  Customer,
} from "src/types/customer"
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

const useCustomer = (
  ): Customer[] => {
    const isMounted = useMounted();
    const [customer, setCustomer] = useState<Customer[]>([]);
    const request = {
        page: 0,
        rowsPerPage: 10,
        sortBy: "createAt",
        //sortDir: "asc",
    }  
  
    const handleCustomerGet = useCallback(async () => {
      try {
        const response = await customersApi.getCustomers(request, 10);
        let customer = response.data as Customer[];
  
        if (isMounted()) {
          setCustomer(customer);
        }
      } catch (err) {
        console.error(err);
      }
    }, [isMounted]);
  
    useEffect(
      () => {
        handleCustomerGet();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
  
    return customer;
  };

export const CustomersButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const customer = useCustomer();
  const { t } = useTranslation()
  
  return (
    <>
      <Tooltip title={t(tokens.customerScreen.last_customer_details)}>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon>
            <Users03Icon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <CustomersPopover
        anchorEl={popover.anchorRef.current}
        customers={customer}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
