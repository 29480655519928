import type { RouteObject } from "react-router"
import { Outlet } from "react-router-dom"
import HomePage from "src/pages"
import Error401Page from "src/pages/401"
import Error404Page from "src/pages/404"
import Error500Page from "src/pages/500"
import ContactPage from "src/pages/contact"
import CheckoutPage from "src/pages/checkout"
import PricingPage from "src/pages/pricing"
import { authRoutes } from "./auth"
import { authDemoRoutes } from "./auth-demo"
import { componentsRoutes } from "./components"
import { dashboardRoutes } from "./dashboard"
import Login from "src/pages/auth/firebase/login"
import { Layout } from "src/layouts/auth/modern-layout"
export const routes: RouteObject[] = [
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        index: false,
        element: <HomePage />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
      ...componentsRoutes,
    ],
  },
  ...authRoutes,
  ...authDemoRoutes,
  ...dashboardRoutes,
  {
    path: "checkout",
    element: <CheckoutPage />,
  },
  {
    path: "contact",
    element: <ContactPage />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
]
