class ApiLogger {
  private enableLog: boolean;

  constructor(enableLog: boolean) {
    this.enableLog = enableLog;
  }

  log(message: any) {
    if (this.enableLog) {
      console.log(message);
    }
  }

  debug(message: any) {
    if (this.enableLog) {
      console.debug(message);
    }
  }

  trace(message: any) {
    if (this.enableLog) {
      console.trace(message);
    }
  }

  info(message: any) {
    if (this.enableLog) {
      console.info(message);
    }
  }

  warn(message: any) {
    if (this.enableLog) {
      console.warn(message);
    }
  }

  error(message: any) {
    if (this.enableLog) {
      console.error(message);
    }
  }
}

export default ApiLogger;
