import type { FC } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  Typography
} from '@mui/material';

import type {
  Customer,
} from "src/types/customer"
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { Scrollbar } from 'src/components/scrollbar';
import { useRouter, Router } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { SeverityPill, SeverityPillColor } from 'src/components/severity-pill';

interface CustomersPopoverProps {
  anchorEl: null | Element;
  customers?: Customer[];
  onClose?: () => void;
  open?: boolean;
}

const statusMap: Record<string, SeverityPillColor> = {
  active: 'success',
  disable: 'error'
};

const renderContent = (customer: Customer, router: Router, t: ((text: string) => string), onClose: (() => void) | undefined): JSX.Element | null => {  
  const createdDate = customer.createdAt ? format(
    parseISO(customer.createdAt),
    "dd/MM/yyyy"
  ) : ""     
  const isActiveLabel = customer.isActive ? t(tokens.customerScreen.status_active) : t(tokens.customerScreen.status_enabled)
  const statusColor = statusMap[customer.isActive ? "active" : "disable"];

  const onClick = () => {
    if(onClose) {
      onClose()
    }

    router.push(paths.dashboard.customers.edit.replaceAll(":customerId", customer.id))
  }
  
  return (
    <>
      <ListItemAvatar sx={{ mt: 0.5 }} onClick={() => onClick()}>
              <Avatar
                src={customer.avatar}
                sx={{ cursor: 'pointer' }}
              />
            </ListItemAvatar>          
      <ListItemText
        onClick={() => onClick()}
        primary={(
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start', 
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ mr: 0.5 }}
            >
              {customer.name}
            </Typography>
            
            <SeverityPill color={statusColor}>
              {isActiveLabel }
            </SeverityPill>
          </Box>
        )}
        secondary={(
          <Typography
            color="text.secondary"
            variant="caption"
          >
            {t(tokens.customerScreen.customer_created_at)} {createdDate}
          </Typography>
        )}
        sx={{ my: 0 }}
      />
    </>
  );
};


export const CustomersPopover: FC<CustomersPopoverProps> = (props) => {
  const { anchorEl, customers = [], onClose, open = false, ...other } = props;
  const { t } = useTranslation()
  const router = useRouter()
  const isEmpty = customers.length === 0;
  
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography
          color="inherit"
          variant="h6"
        >
          {t(tokens.customerScreen.last_customer_details)}
        </Typography>
      </Stack>
      {
        isEmpty
          ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle2">
              {t(tokens.notification.dashboard.not_found)}
              </Typography>
            </Box>
          )
          : (
            <Scrollbar sx={{ maxHeight: 400 }}>
              <List disablePadding>
                {customers.map((customer) => (
                  <ListItem
                    divider
                    key={`${customer.id}-${customer.documentNumber}`} 
                    sx={{
                      alignItems: 'flex-start',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      },
                      '& .MuiListItemSecondaryAction-root': {
                        top: '24%'
                      }
                    }}
                  >
                    {renderContent(customer, router, t, onClose)}
                  </ListItem>
                ))}
              </List>
            </Scrollbar>
          )
      }
    </Popover>
  );
};



CustomersPopover.propTypes = {
  anchorEl: PropTypes.any,
  customers: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
