import type { ReactNode } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { SvgIcon } from "@mui/material"
import CalendarIcon from "src/icons/untitled-ui/duocolor/calendar"
import CheckDone01Icon from "src/icons/untitled-ui/duocolor/check-done-01"
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile"
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02"
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square"
import ReceiptCheckIcon from "src/icons/untitled-ui/duocolor/receipt-check"
import ShoppingBag03Icon from "src/icons/untitled-ui/duocolor/shopping-bag-03"
import ShoppingCart01Icon from "src/icons/untitled-ui/duocolor/shopping-cart-01"
import Truck01Icon from "src/icons/untitled-ui/duocolor/truck-01"
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03"
import User03Icon from "@untitled-ui/icons-react/build/esm/User03"
import ApartmentIcon from "@mui/icons-material/Apartment"
import RoomServiceIcon from "@mui/icons-material/RoomService"
import StoreIcon from '@mui/icons-material/Store';

import { tokens } from "src/locales/tokens"
import { paths } from "src/paths"

export interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
}

export interface Section {
  items: Item[]
  subheader?: string
}

export const useSections = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <User03Icon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.staff),
        items: [
          {
            title: t(tokens.nav.users),
            path: paths.dashboard.users.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.concierge),
            path: paths.dashboard.concierge.index,
            icon: (
              <SvgIcon fontSize="small">
                <RoomServiceIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.register),
            icon: (
              <SvgIcon fontSize="small">
                <CheckDone01Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.event),
                path: paths.dashboard.register.event.index,
              },
              {
                title: t(tokens.nav.orderType),
                path: paths.dashboard.register.orderType.index,
              },
              // {
              //   title: t(tokens.nav.requestStatus),
              //   path: paths.dashboard.register.requestStatus.index,
              // },
            ],
          },

        ]
      },
      {
        subheader: t(tokens.nav.customerMenu),
        items: [
          {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.condominium),
            path: paths.dashboard.condominium.index,
            icon: (
              <SvgIcon fontSize="small">
                <ApartmentIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.paymentList),
            path: paths.dashboard.payments.index,
            icon: (
              <SvgIcon fontSize="small">
                <ReceiptCheckIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.payments.index,
              },
              // {
              //   title: t(tokens.nav.details),
              //   path: paths.dashboard.payments.details,
              // },
            ],
          },

        ]
      },
      {
        subheader: t(tokens.nav.concepts),
        items: [
          {
            title: t(tokens.nav.requests),
            path: paths.dashboard.orders.index,
            icon: (
              <SvgIcon fontSize="small">
                <ShoppingCart01Icon />
              </SvgIcon>
            ),
          },
          
          {
            title: t(tokens.nav.businessPartner),
            path: paths.dashboard.businessPartner.index,
            icon: (
              <SvgIcon fontSize="small">
                <StoreIcon />
              </SvgIcon>
            ),
          },
          
          
          
          
        ],
      },
    ]
  }, [t])
}
