export const paths = {
  index: "/",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    auth0: {
      callback: "/auth/auth0/callback",
      login: "/auth/auth0/login",
    },
    jwt: {
      login: "/auth/jwt/login",
      register: "/auth/jwt/register",
    },
    firebase: {
      login: "/auth/firebase/login",
      register: "/auth/firebase/register",
    },
    amplify: {
      confirmRegister: "/auth/amplify/confirm-register",
      forgotPassword: "/auth/amplify/forgot-password",
      login: "/auth/amplify/login",
      register: "/auth/amplify/register",
      resetPassword: "/auth/amplify/reset-password",
    },
  },
  authDemo: {
    forgotPassword: {
      classic: "/auth-demo/forgot-password/classic",
      modern: "/auth-demo/forgot-password/modern",
    },
    login: {
      classic: "/auth-demo/login/classic",
      modern: "/auth-demo/login/modern",
    },
    register: {
      classic: "/auth-demo/register/classic",
      modern: "/auth-demo/register/modern",
    },
    resetPassword: {
      classic: "/auth-demo/reset-password/classic",
      modern: "/auth-demo/reset-password/modern",
    },
    verifyCode: {
      classic: "/auth-demo/verify-code/classic",
      modern: "/auth-demo/verify-code/modern",
    },
  },
  dashboard: {
    index: "/dashboard",
    orders: {
      index: "/dashboard/orders",
      detail: "/dashboard/orders/details/:orderId",
      create: "/dashboard/orders/create",
      chat: "/dashboard/orders/chat",
    },
    concierge: {
      index: "/dashboard/concierge",
      create: "/dashboard/concierge/create",
      edit: "/dashboard/concierge/edit",
    },
    condominium: {
      index: "/dashboard/condominium",
      create: "/dashboard/condominium/create",
      edit: "/dashboard/condominium/edit",
    },
    businessPartner: {
      index: "/dashboard/businessPartner",
      create: "/dashboard/businessPartner/create",
      edit: "/dashboard/businessPartner/edit",
      services: {
        index: "/dashboard/businessPartner",
        service: "/service",
        create: "/service/create",
        edit: "/service/edit",
      },
    },
    register: {
      event: {
        index: "/dashboard/register/event",
        create: "/dashboard/register/event/create",
        edit: "/dashboard/register/event/edit",
        customers: {
          index: "/customers",
        },
      },
      orderType: {
        index: "/dashboard/register/orderType",
        create: "/dashboard/register/orderType/create",
        edit: "/dashboard/register/orderType/edit",
      },
      requestStatus: {
        index: "/dashboard/register/requestStatus",
        create: "/dashboard/register/requestStatus/create",
        edit: "/dashboard/register/requestStatus/edit",
      },
    },
    academy: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/courses/:courseId",
    },
    account: "/dashboard/account",
    analytics: "/dashboard/analytics",
    blank: "/dashboard/blank",
    blog: {
      index: "/dashboard/blog",
      postDetails: "/dashboard/blog/:postId",
      postCreate: "/dashboard/blog/create",
    },
    calendar: "/dashboard/calendar",
    chat: "/dashboard/chat",
    crypto: "/dashboard/crypto",
    customers: {
      index: "/dashboard/customers",
      details: "/dashboard/customers/:customerId/details",
      create: "/dashboard/customers/edit",
      edit: "/dashboard/customers/:customerId/edit",
      address: {
        edit: "/dashboard/customers/:customerId/address/:addressId",
        create: "/dashboard/customers/:customerId/address",
      },
      contacts: {
        edit: "/dashboard/customers/:customerId/contacts/:contactId",
        create: "/dashboard/customers/:customerId/contacts",
      },
    },
    users: {
      index: "/dashboard/users",
      details: "/dashboard/users/:userId",
      create: "/dashboard/users/create",
      edit: "/dashboard/users/:userId/edit",
    },
    ecommerce: "/dashboard/ecommerce",
    fileManager: "/dashboard/file-manager",
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
    payments: {
      index: "/dashboard/payments",
      details: "/dashboard/payments",
    },
    jobs: {
      index: "/dashboard/jobs",
      create: "/dashboard/jobs/create",
      companies: {
        details: "/dashboard/jobs/companies/:companyId",
      },
    },
    kanban: "/dashboard/kanban",
    logistics: {
      index: "/dashboard/logistics",
      fleet: "/dashboard/logistics/fleet",
    },
    mail: "/dashboard/mail",
    ordersOld: {
      index: "/dashboard/orders/old",
      details: "/dashboard/orders/old/:orderId",
    },
    products: {
      index: "/dashboard/products",
      create: "/dashboard/products/create",
    },
    social: {
      index: "/dashboard/social",
      profile: "/dashboard/social/profile",
      feed: "/dashboard/social/feed",
    },
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  docs: "https://material-kit-pro-react-docs.devias.io",
  401: "/401",
  404: "/404",
  500: "/500",
}
