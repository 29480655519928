import { lazy, Suspense } from "react"
import type { RouteObject } from "react-router"
import { Outlet } from "react-router-dom"
import { Layout as DashboardLayout } from "src/layouts/dashboard"

const IndexPage = lazy(() => import("src/pages/dashboard/index"))

//Requests
const RequestsListPage = lazy(() => import("src/pages/dashboard/requests/list"))
const RequestDetailPage = lazy(() => import("src/pages/dashboard/requests/detail"))
const RequestCreatePage = lazy(() => import("src/pages/dashboard/requests/create"))
const RequestChatPage = lazy(() => import("src/pages/dashboard/requests/chat"))

//Order Type
const OrderTypeListPage = lazy(() => import("src/pages/dashboard/order-type/list"))
const OrderTypeCreatePage = lazy(() => import("src/pages/dashboard/order-type/create"))
const OrderTypeEditPage = lazy(() => import("src/pages/dashboard/order-type/create"))

//Request Type
const EventListPage = lazy(() => import("src/pages/dashboard/event/list"))
const EventCreatePage = lazy(() => import("src/pages/dashboard/event/create"))
const EventEditPage = lazy(() => import("src/pages/dashboard/event/create"))

//Condominium
const CondominiumListPage = lazy(() => import("src/pages/dashboard/condominium/list"))
const CondominiumCreatePage = lazy(() => import("src/pages/dashboard/condominium/create"))
const CondominiumEditPage = lazy(() => import("src/pages/dashboard/condominium/create"))

//Concierge
const ConciergeListPage = lazy(() => import("src/pages/dashboard/concierge/list"))
const ConciergeCreatePage = lazy(() => import("src/pages/dashboard/concierge/create"))
const ConciergeEditPage = lazy(() => import("src/pages/dashboard/concierge/create"))

//Business partner
const BusinessPartnerListPage = lazy(() => import("src/pages/dashboard/business-partner/list"))
const BusinessPartnerCreatePage = lazy(() => import("src/pages/dashboard/business-partner/create"))
const BusinessPartnerEditPage = lazy(() => import("src/pages/dashboard/business-partner/create"))

//Business partner service
const BusinessPartnerServiceListPage = lazy(
  () => import("src/pages/dashboard/business-partner/service/list")
)
const BusinessPartnerServiceCreatePage = lazy(
  () => import("src/pages/dashboard/business-partner/service/create")
)
const BusinessPartnerServiceEditPage = lazy(
  () => import("src/pages/dashboard/business-partner/service/create")
)

//Business partner service
const CustomersEventListPage = lazy(() => import("src/pages/dashboard/event/customers-event/list"))

// Academy
// const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
// const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Blog
// const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
// const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
// const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));

// Customers
const CustomerListPage = lazy(() => import("src/pages/dashboard/customers/list"))
const CustomerDetailPage = lazy(() => import("src/pages/dashboard/customers/detail"))
const CustomerEditPage = lazy(() => import("src/pages/dashboard/customers/edit"))
const CustomerAddressEditPage = lazy(() => import("src/pages/dashboard/customers/create-address"))
const CustomerAddressCreatePage = lazy(() => import("src/pages/dashboard/customers/create-address"))
const CustomerContactsEditPage = lazy(() => import("src/pages/dashboard/customers/create-contacts"))
const CustomerContactsCreatePage = lazy(
  () => import("src/pages/dashboard/customers/create-contacts")
)

// Users
const UserListPage = lazy(() => import("src/pages/dashboard/user/list"))
const UserCreatePage = lazy(() => import("src/pages/dashboard/user/create"))
const UserEditPage = lazy(() => import("src/pages/dashboard/user/edit"))

// Invoice
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
// const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Invoice
const PaymentListPage = lazy(() => import("src/pages/dashboard/payment/list"))
const PaymentDetailPage = lazy(() => import("src/pages/dashboard/payment/detail"))

// Jobs
// const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
// const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
// const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
// const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
// const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Orders
// const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
// const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Products
// const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
// const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Social
// const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
// const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"))
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"))
const AccountPage = lazy(() => import("src/pages/dashboard/account"))
// const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
// const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
// const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// const MailPage = lazy(() => import('src/pages/dashboard/mail'));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "orders",
        children: [
          {
            index: true,
            element: <RequestsListPage />,
          },
          {
            path: "create",
            element: <RequestCreatePage />,
          },
          {
            path: "chat/:orderId",
            element: <RequestChatPage />,
          },
        ],
      },
      {
        path: "concierge",
        children: [
          {
            index: true,
            element: <ConciergeListPage />,
          },
          {
            path: "create",
            element: <ConciergeCreatePage />,
          },
          {
            path: "edit/:conciergeId",
            element: <ConciergeEditPage />,
          },
        ],
      },
      {
        path: "condominium",
        children: [
          {
            index: true,
            element: <CondominiumListPage />,
          },
          {
            path: "create",
            element: <CondominiumCreatePage />,
          },
          {
            path: "edit/:condominiumId",
            element: <CondominiumEditPage />,
          },
        ],
      },
      {
        path: "businessPartner",
        children: [
          {
            index: true,
            element: <BusinessPartnerListPage />,
          },
          {
            path: "create",
            element: <BusinessPartnerCreatePage />,
          },
          {
            path: "edit/:condominiumId",
            element: <BusinessPartnerEditPage />,
          },
          {
            path: ":businessPartnerId",
            children: [
              {
                path: "service",
                element: <BusinessPartnerServiceListPage />,
              },
              {
                path: "service/create",
                element: <BusinessPartnerServiceCreatePage />,
              },
              {
                path: "service/edit/:serviceId",
                element: <BusinessPartnerServiceEditPage />,
              },
            ],
          },
        ],
      },
      {
        path: "register",
        children: [
          {
            path: "orderType",
            children: [
              {
                index: true,
                element: <OrderTypeListPage />,
              },
              {
                path: "create",
                element: <OrderTypeCreatePage />,
              },
              {
                path: "edit/:orderTypeId",
                element: <OrderTypeEditPage />,
              },
            ],
          },
          {
            path: "event",
            children: [
              {
                index: true,
                element: <EventListPage />,
              },
              {
                path: "create",
                element: <EventCreatePage />,
              },
              {
                path: "edit/:eventId",
                element: <EventEditPage />,
              },
              {
                path: ":eventId",
                children: [
                  {
                    path: "customers",
                    element: <CustomersEventListPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "requestStatus",
            children: [
              {
                index: true,
                element: <RequestsListPage />,
              },
              {
                path: "create/:requestId",
                element: <RequestDetailPage />,
              },
              {
                path: "edit/:requestId",
                element: <CustomerEditPage />,
              },
            ],
          },
        ],
      },
      // {
      //   path: 'academy',
      //   children: [
      //     {
      //       index: true,
      //       element: <AcademyDashboardPage />
      //     },
      //     {
      //       path: 'courses',
      //       children: [
      //         {
      //           path: ':courseId',
      //           element: <AcademyCoursePage />
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: 'blog',
      //   children: [
      //     {
      //       index: true,
      //       element: <BlogPostListPage />
      //     },
      //     {
      //       path: 'create',
      //       element: <BlogPostCreatePage />
      //     },
      //     {
      //       path: ':postId',
      //       element: <BlogPostDetailPage />
      //     }
      //   ]
      // },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <UserListPage />,
          },
          {
            path: ":userId",
            element: <UserCreatePage />,
          },
          {
            path: ":userId/edit",
            element: <UserEditPage />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: ":customerId/details",
            element: <CustomerDetailPage />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEditPage />,
          },
          {
            path: ":customerId/address/:addressId",
            element: <CustomerAddressEditPage />,
          },
          {
            path: ":customerId/address",
            element: <CustomerAddressCreatePage />,
          },
          {
            path: ":customerId/contacts/:contactsId",
            element: <CustomerContactsEditPage />,
          },
          {
            path: ":customerId/contacts",
            element: <CustomerContactsCreatePage />,
          },
        ],
      },
      {
        path: "payments",
        children: [
          {
            index: true,
            element: <PaymentListPage />,
          },
          {
            path: ":orderId",
            element: <PaymentDetailPage />,
          },
        ],
      },
      // {
      //   path: 'invoices',
      //   children: [
      //     {
      //       index: true,
      //       element: <InvoiceListPage />
      //     },
      //     {
      //       path: ':invoiceId',
      //       element: <InvoiceDetailPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'jobs',
      //   children: [
      //     {
      //       index: true,
      //       element: <JobBrowsePage />
      //     },
      //     {
      //       path: 'create',
      //       element: <JobCreatePage />
      //     },
      //     {
      //       path: 'companies',
      //       children: [
      //         {
      //           path: ':companyId',
      //           element: <CompanyDetailPage />
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: 'logistics',
      //   children: [
      //     {
      //       index: true,
      //       element: <LogisticsDashboardPage />
      //     },
      //     {
      //       path: 'fleet',
      //       element: <LogisticsFleetPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'ordersOld',
      //   children: [
      //     {
      //       index: true,
      //       element: <OrderListPage />
      //     },
      //     {
      //       path: ':orderId',
      //       element: <OrderDetailPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'products',
      //   children: [
      //     {
      //       index: true,
      //       element: <ProductListPage />
      //     },
      //     {
      //       path: 'create',
      //       element: <ProductCreatePage />
      //     }
      //   ]
      // },
      // {
      //   path: 'social',
      //   children: [
      //     {
      //       path: 'feed',
      //       element: <SocialFeedPage />
      //     },
      //     {
      //       path: 'profile',
      //       element: <SocialProfilePage />
      //     }
      //   ]
      // },
      {
        path: "account",
        element: <AccountPage />,
      },
      // {
      //   path: 'analytics',
      //   element: <AnalyticsPage />
      // },
      // {
      //   path: 'blank',
      //   element: <BlankPage />
      // },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      // {
      //   path: 'chat',
      //   element: <ChatPage />
      // },
      // {
      //   path: 'crypto',
      //   element: <CryptoPage />
      // },
      // {
      //   path: 'ecommerce',
      //   element: <EcommercePage />
      // },
      // {
      //   path: 'file-manager',
      //   element: <FileManagerPage />
      // },
      {
        path: "kanban",
        element: <KanbanPage />,
      },
      // {
      //   path: 'mail',
      //   element: <MailPage />
      // }
    ],
  },
]
