import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { getMessaging, isSupported } from "firebase/messaging"
import { firebaseConfig } from "src/config"

export const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore(firebaseApp)
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp)
    }
    console.log("Firebase is not supported in this browser")
    return null
  } catch (err) {
    console.log(err)
    return null
  }
})()
