import type { FC } from 'react';
import PropTypes, { string } from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import { SxProps } from '@mui/system';
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { TenantPopover } from './tenant-popover';
import { boolean } from 'yup';

const tenants: string[] = [];

interface TenantSwitchProps {
  sx?: SxProps;
}

const isProductionDomain = (domain: string): boolean => {
  const productionDomains = [
    "donna.getpresto.app",
   
  ];

  return productionDomains.includes(domain);
};


export const TenantSwitch: FC<TenantSwitchProps> = (props) => {
  const popover = usePopover<HTMLButtonElement>();
  const currentDomain = window.location.hostname;
  const isProd = isProductionDomain(currentDomain);
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            variant="h6"
          >
            Presto
          </Typography>
          <Typography
            color="neutral.400"
            variant="body2"
          >
            {/* release */}
            App - Beta v. 0.01
          </Typography>
          <Typography
            color="neutral.400"
            variant="body2"
          >
            {/* release */}
            {isProd ? 'Release' : 'Development'}

          </Typography>
        </Box>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  // @ts-ignore
  sx: PropTypes.object
};


