import { getToken } from "firebase/messaging"
import { messaging } from "../firestore-base"
import { notificationConfig } from "src/config"
import { notificationApi } from "src/api/notification"

class NoticationMessaging {
  async subscribeToTopicByName(topicName: string): Promise<void> {
    const messagingResolve = await messaging
    if (messagingResolve) {
      this.getFirebaseToken()
        .then((token) => {
          if (token) {
            notificationApi
              .subscribeToTopicByName(topicName, token)
              .then((response) => {
                console.log("Successfully subscribed to topic:", response)
              })
              .catch((error) => {
                console.log("Error subscribing to topic:", error)
              })
          }
        })
        .catch((ex) => {
          console.log(`ERROR: ${ex}`)
        })
    }
  }

  async getFirebaseToken(): Promise<string | undefined> {
    try {
      const messagingResolve = await messaging
      if (messagingResolve) {
        return this.getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
          return Promise.resolve(
            getToken(messagingResolve, {
              vapidKey: notificationConfig.apiKey,
              serviceWorkerRegistration,
            })
          )
        })
      }
    } catch (error) {
      console.log("An error occurred while retrieving token. ", error)
      return undefined
    }
  }

  private getOrRegisterServiceWorker(): Promise<ServiceWorkerRegistration> {
    if ("serviceWorker" in navigator && typeof window.navigator.serviceWorker !== "undefined") {
      return window.navigator.serviceWorker
        .getRegistration("/firebase-push-notification-scope")
        .then((serviceWorker) => {
          if (serviceWorker) return serviceWorker
          return window.navigator.serviceWorker.register("./firebase-messaging-sw.js", {
            scope: "/firebase-push-notification-scope",
          })
        })
    }
    throw new Error("The browser doesn`t support service worker.")
  }
}

export const noticationMessaging = new NoticationMessaging()
