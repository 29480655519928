import type { FC } from "react"
import { Helmet } from "react-helmet-async"
import PropTypes from "prop-types"

interface SeoProps {
  title?: string
}

export const Seo: FC<SeoProps> = props => {
  const { title } = props

  const fullTitle = title ? title + " | Presto Tech" : "Presto Tech"

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta
        name="description"
        content="Presto é uma empresa de prestação de serviços para facilitar a vida e poupar tempo."
      />
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
}
