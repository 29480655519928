import type { FC } from "react"
import { useTheme } from "@mui/material/styles"

export const Logo: FC = () => {
  const theme = useTheme()
  const fillColor = theme.palette.primary.main

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="25px"
      viewBox="0 0 22.8 20.1"
      version="1.1"
    >
      <g id="surface1">
        <path
          d="M 12.203125 1.214844 C 13.257812 1.175781 13.945312 1.640625 14.269531 2.613281 C 14.398438 3.609375 13.996094 4.304688 13.058594 4.695312 C 11.910156 4.953125 11.125 4.550781 10.703125 3.476562 C 9.539062 3.65625 8.457031 4.0625 7.460938 4.695312 C 5.171875 6.195312 3.765625 8.277344 3.242188 10.933594 C 3.054688 11.085938 2.894531 11.0625 2.765625 10.867188 C 2.769531 10.515625 2.824219 10.171875 2.9375 9.835938 C 3.542969 7.78125 4.699219 6.089844 6.402344 4.761719 C 7.488281 3.953125 8.695312 3.375 10.019531 3.027344 C 10.234375 2.976562 10.449219 2.9375 10.667969 2.914062 C 10.757812 1.980469 11.269531 1.417969 12.203125 1.214844 Z M 12.203125 1.214844 "
          fill={fillColor}
        />
        <path
          d="M 17.324219 4.109375 C 17.40625 4.105469 17.484375 4.109375 17.566406 4.128906 C 20.257812 5.789062 21.835938 8.167969 22.292969 11.269531 C 22.398438 12.292969 22.347656 13.304688 22.140625 14.3125 C 22.464844 14.527344 22.730469 14.800781 22.941406 15.128906 C 23.328125 16.015625 23.152344 16.777344 22.414062 17.410156 C 21.632812 17.894531 20.871094 17.871094 20.125 17.34375 C 19.367188 16.578125 19.28125 15.753906 19.867188 14.863281 C 20.324219 14.320312 20.910156 14.097656 21.628906 14.195312 C 22.140625 11.246094 21.417969 8.628906 19.460938 6.339844 C 18.796875 5.652344 18.0625 5.050781 17.257812 4.542969 C 17.199219 4.386719 17.222656 4.242188 17.324219 4.109375 Z M 17.324219 4.109375 "
          fill={fillColor}
        />
        <path
          d="M 3.363281 14.164062 C 4.273438 14.128906 4.929688 14.503906 5.324219 15.296875 C 5.535156 15.894531 5.46875 16.460938 5.121094 16.992188 C 4.988281 17.144531 4.859375 17.292969 4.726562 17.441406 C 6.105469 19.449219 8.003906 20.722656 10.429688 21.269531 C 12.796875 21.742188 15.039062 21.410156 17.15625 20.273438 C 17.449219 20.214844 17.582031 20.332031 17.546875 20.621094 C 17.515625 20.65625 17.488281 20.695312 17.460938 20.738281 C 14.894531 22.097656 12.210938 22.375 9.40625 21.570312 C 7.203125 20.847656 5.460938 19.542969 4.183594 17.660156 C 3.335938 17.90625 2.644531 17.683594 2.117188 16.992188 C 1.597656 16.105469 1.703125 15.300781 2.441406 14.578125 C 2.714844 14.367188 3.023438 14.230469 3.363281 14.164062 Z M 3.363281 14.164062 "
          fill={fillColor}
        />
      </g>
    </svg>
  )
}
