import { apiClient } from "src/api/base/apiClient"
import Avatar from "src/libs/avatar"
import type {
  Address,
  Condominium,
  Customer,
  CustomerEmail,
  CustomerInvoice,
  CustomerLog,
  ICustomers,
  IMeta,
  OrdersSummary,
  UpdateCondominiumOutput,
  UpdateCustomerOutput,
  UpdateDocumentOutput,
} from "src/types/customer"
import { ICustomer } from "src/types/order"
import { applyPagination } from "src/utils/apply-pagination"
import { applySort } from "src/utils/apply-sort"
import { deepCopy } from "src/utils/deep-copy"
import { addresses, customer, emails, invoices, logs } from "./data"

type GetCustomersRequest = {
  filters?: {
    query?: string
    queryType?: string
    hasAcceptedMarketing?: boolean
    isApproval?: boolean
    isReturning?: boolean
    brand: string
  }
  page?: number
  pageSelected?: number
  rowsPerPage?: number
  searchBy?: string
  sortBy?: string
  sortDir?: "asc" | "desc"
}

type GetCustomersResponse = Promise<{
  data: Customer[]
  count: number
}>

type GetCustomerRequest = {}

type GetCustomerResponse = Promise<Customer>

type GetICustomerResponse = Promise<Customer>

type GetICustomerOrderResponse = Promise<ICustomer>

type GetCustomerEmailsRequest = {}

type GetCustomerEmailsResponse = Promise<CustomerEmail[]>

type GetCustomerInvoicesRequest = {}

type GetCustomerInvoicesResponse = Promise<CustomerInvoice[]>

type GetCustomerLogsRequest = {}

type GetCustomerAddressesRequest = {}

type GetCustomerLogsResponse = Promise<CustomerLog[]>

type GetCustomerAddressesResponse = Promise<Address[]>

class CustomersApi {
  private avatarInstance = new Avatar()

  mapCustomer(customerMap: any): Customer {
    let contacts: CustomerEmail[] = customerMap.contacts.map((contactMap: any) => {
      return {
        id: contactMap.id,
        description: contactMap.description,
        createdAt: contactMap.createdAt,
        updatedAt: contactMap.updatedAt,
        phoneArea: contactMap.phoneArea,
        phoneCode: contactMap.phoneCode,
        phoneNumber: contactMap.phoneNumber,
        talkTo: contactMap.talkTo,
        email: contactMap.email,
        mainContact: contactMap.mainContact,
      }
    })

    let addresses: Address[] = customerMap.addresses.map((addressMap: any) => {
      return {
        createdAt: addressMap.createdAt,
        updatedAt: addressMap.updatedAt,
        id: addressMap.id,
        address: addressMap.address,
        streetName: addressMap.streetName,
        number: addressMap.number,
        neighborhood: addressMap.neighborhood,
        complimentaryInformation: addressMap.complimentaryInformation,
        zipcode: addressMap.zipcode,
        city: {
          id: addressMap.city.id,
          city: addressMap.city.city,
        },
        state: {
          id: addressMap.state.id,
          state: addressMap.state.state,
        },
        country: {
          id: addressMap.country.id,
          country: addressMap.country.country,
        },
      }
    })

    let condominium: Condominium | undefined
    if (customerMap.condominium) {
      condominium = {
        id: customerMap.condominium.id,
        name: customerMap.condominium.name,
      }
    }

    let ordersSummary: OrdersSummary | undefined = customerMap.ordersSummary
      ? {
          amountOrders: customerMap.ordersSummary.amountOrders || 0,
          grossValueTotal: customerMap.ordersSummary.grossValueTotal,
          netvalueTotal: customerMap.ordersSummary.netvalueTotal,
          feeTotal: customerMap.ordersSummary.feeTotal,
          lastOrderDate: customerMap.ordersSummary.lastOrderDate,
        }
      : undefined

    return {
      id: customerMap.id,
      avatar: customerMap.avatar,
      balance: customerMap.balance,
      city: customerMap.city,
      country: customerMap.country,
      currency: customerMap.currency,
      email: customerMap.email,
      gender: customerMap.gender,
      hasAcceptedMarketing: customerMap.hasAcceptedMarketing,
      hasDiscount: customerMap.hasDiscount,
      isProspect: customerMap.isProspect,
      isReturning: customerMap.isReturning,
      isVerified: customerMap.isVerified,
      name: customerMap.name,
      firstName: customerMap.firstName,
      lastName: customerMap.lastName,
      documentNumber: customerMap.documentNumber,
      brand: customerMap.brand,
      phone: customerMap.phone,
      state: customerMap.state,
      totalSpent: customerMap.totalSpent,
      totalOrders: customerMap.totalOrders,
      updatedAt: customerMap.updatedAt,
      vatRate: customerMap.vatRate,
      zipCode: customerMap.zipCode,
      contacts: contacts,
      addresses: addresses,
      condominium: condominium,
      ordersSummary: ordersSummary,
    }
  }
  formatCurrencyBrazil(number: number) {
    if (typeof number !== "number") {
      return "Invalid value"
    }

    const formattedValue = number.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return formattedValue
  }

  getCustomers(request: GetCustomersRequest = {}, size: number = 100): GetCustomersResponse {
    const { filters, page, pageSelected, rowsPerPage, searchBy, sortBy, sortDir } = request
    let countPage = ((((page as number) * rowsPerPage!) as number) + rowsPerPage!) as number
    let pageChoose = page as number
    const currentPageMultiple = Math.ceil(countPage / size)

    if (pageChoose > 0) {
      pageChoose = currentPageMultiple
    }

    if (typeof pageSelected !== "undefined") {
      pageChoose = pageSelected as number
    }

    if (typeof filters !== "undefined") {
      if(filters.brand == "PRE" || filters.brand == "PRO"){
        apiClient.setCustomBrand(filters.brand);
      } else{
        apiClient.setCustomBrand("PRE");
      }
    }

    let queryString = `v1/donna/customer?Page=${pageChoose}&PageSize=${size}`
    if (filters?.queryType && filters.queryType.trim() !== "") {
      queryString = queryString + `&${filters?.queryType}=${filters?.query}`
    }

    return apiClient.get(queryString).then((response) => {
      let customers = response as ICustomers[]
      let meta = response.meta as IMeta
      let data: Customer[] = response.customers.map((customerMap: any) => {
        let customer = this.mapCustomer(customerMap)

        return {
          id: customerMap.id,
          avatar: this.avatarInstance.getAvatarTag(customerMap.id, customerMap.gender.id || "M"),
          city: customer.addresses?.[0]?.city?.city || "Não informado",
          country: customer.addresses?.[0]?.country?.country || "-",
          isActive: customerMap.isActive,
          currency: "R$",
          email: customer.contacts?.[0]?.email,
          hasAcceptedMarketing: customer.contacts?.[0]?.mainContact,
          isProspect: false,
          isReturning: true,
          gender: customerMap.gender,
          name: customerMap.fullName,
          firstName: customerMap.firstName,
          lastName: customerMap.lastName,
          documentNumber: customerMap.documentNumber,
          condominium: customerMap.condominium,
          contacts: customerMap.contacts,
          state: customer.addresses?.[0]?.state?.id || "-",
          totalSpent:
            this.formatCurrencyBrazil((customer.ordersSummary?.grossValueTotal || 0) as number) ||
            0,
          totalOrders: customer.ordersSummary?.amountOrders || 0,
          createdAt: customerMap.createdAt,
          brand: customerMap.brand
        }
      })

      let count = data.length

      if (typeof filters !== "undefined") {
        data = data.filter((customer) => {
          if (typeof filters.query !== "undefined" && filters.query !== "") {
            let queryMatched = false
            const properties: ("email" | "name" | "city")[] = ["email", "name", "city"]

            properties.forEach((property) => {
              if (
                customer! &&
                customer![property] &&
                customer![property]!.toLowerCase().includes(filters.query!.toLowerCase())
              ) {
                queryMatched = true
              }
            })

            if (!queryMatched) {
              return false
            }
          }

          if (typeof filters.isReturning !== "undefined") {
            if (customer.isReturning !== filters.isReturning) {
              return false
            }
          }

          return true
        })
        count = meta.totalPages
      }

      if (typeof filters?.hasAcceptedMarketing !== "undefined") {
        data = data.filter((user) => user.hasAcceptedMarketing === true)
        count = data.length
      }

      if (filters?.isApproval !== undefined) {
        data = data.filter((user) => user.isActive === false)
        count = data.length
      }

      if (typeof sortBy !== "undefined" && typeof sortDir !== "undefined") {
        data = applySort(data, sortBy, sortDir)
      }

      if (typeof page !== "undefined" && typeof rowsPerPage !== "undefined") {
        data = applyPagination(data, pageChoose, rowsPerPage)
      }

      return Promise.resolve({
        data,
        count,
      })
    })
  }

  getCustomerByDocumentNumber(documentNumber: number | undefined): GetICustomerOrderResponse {
    if (typeof documentNumber === "undefined") {
      return Promise.resolve({} as GetICustomerOrderResponse)
    }

    return apiClient.get(`v1/donna/customer?documentNumber=${documentNumber}`).then((response) => {
      return response[0] as ICustomer
    })
  }

  async getCustomerByCustomerId(customerId: number | undefined): GetICustomerResponse {
    if (typeof customerId === "undefined") {
      return Promise.resolve({} as GetICustomerResponse)
    }
    
    const customer = await apiClient.get(`v1/donna/customer/${customerId}`)
    //let customer = this.mapCustomer(response);

    return {
      id: customer.id,
      avatar: this.avatarInstance.getAvatarTag(customer.id, customer.gender.id || "M"),
      city: customer.addresses?.[0]?.city?.city || " ",
      country: customer.addresses?.[0]?.country?.country || " ",
      isActive: customer.isActive,
      gender: customer.gender.id,
      condominium: {
        id: customer.condominium.id,
        name: customer.condominium.name,
      },
      MaritalStatus: customer.maritalStatus.id,
      notes: customer.notes,
      phone: customer.contacts?.[0]?.phoneNumber || "-",
      currency: "R$",
      email: customer.contacts?.[0]?.email,
      hasAcceptedMarketing: customer.contacts?.[0]?.phoneNumber,
      isProspect: false,
      isReturning: true,
      name: customer.fullName,
      hasErrorOnGateway: customer.customerIdGateway == null ? false : true,
      firstName: customer.firstName,
      lastName: customer.lastName,
      createdAt: customer.createdAt,
      acceptMarketingContact: customer.acceptMarketingContact,
      updatedAt: customer.updatedAt,
      state: customer.addresses?.[0]?.state?.id || " ",
      address1: customer.addresses?.[0]?.address || " ",
      documentNumber: customer.documentNumber,
      documentType: customer.documentType,
      ordersSummary: {
        amountOrders: customer.ordersSummary?.amountOrders || 0,
        grossValueTotal: customer.ordersSummary?.grossValueTotal || 0,
        netvalueTotal: customer.ordersSummary?.netvalueTotal || 0,
        feeTotal: customer.ordersSummary?.feeTotal || 0,
        lastOrderDate: customer.ordersSummary?.lastOrderDate || "",
      },
      birthDate: customer.birthDate,
      brand: customer.brand
    }

    //return response;
  }

  updateCustomer(customerId: Number, body: Customer): Promise<void> {
    if (typeof customerId === "undefined" || typeof body === "undefined") {
      return Promise.resolve()
    }
    let customer = this.mapCustomerToCustomerResponse(body)
    return apiClient.patch(`v1/donna/customer/${customerId}`, customer)
  }

  mapCustomerToCustomerResponse = (customerMap: any): UpdateCustomerOutput => {
    const document: UpdateDocumentOutput = {
      Number: customerMap.documentNumber,
      Type: customerMap.documentType,
    }

    const condominium: UpdateCondominiumOutput = {
      Id: customerMap.condominium.id,
    }

    const customer: UpdateCustomerOutput = {
      Id: customerMap.id,
      FirstName: customerMap.firstName,
      LastName: customerMap.lastName,
      Document: document,
      BirthDate: customerMap.birthDate,
      IsActive: customerMap.isActive,
      acceptMarketingContact: customerMap.acceptMarketingContact,
      Gender: customerMap.gender,
      Condominium: condominium,
      MaritalStatus: customerMap.MaritalStatus,
      brand: customerMap.brand
    }
    return customer
  }

  getCustomer(request?: GetCustomerRequest): GetCustomerResponse {
    return Promise.resolve(deepCopy(customer))
  }

  getEmails(request?: GetCustomerEmailsRequest): GetCustomerEmailsResponse {
    return Promise.resolve(deepCopy(emails))
  }

  getInvoices(request?: GetCustomerInvoicesRequest): GetCustomerInvoicesResponse {
    return Promise.resolve(deepCopy(invoices))
  }

  getLogs(request?: GetCustomerLogsRequest): GetCustomerLogsResponse {
    return Promise.resolve(deepCopy(logs))
  }
  getAddresses(request?: GetCustomerAddressesRequest): GetCustomerAddressesResponse {
    return Promise.resolve(deepCopy(addresses))
  }
}

export const customersApi = new CustomersApi()
