import {
  query,
  collection,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
  where,
  getDocs,
} from "firebase/firestore"
import { db } from "../firestore-base"
import { INotificationTokens } from "src/types/notification"

type NotificationData = Promise<INotificationTokens | undefined>

const notificationCollection = "notificationTokens"

const notificationConverter = {
  toFirestore: (notificationToken: WithFieldValue<INotificationTokens>): DocumentData => {
    return {
      userCode: notificationToken.userCode,
      userName: notificationToken.userName,
      userEmail: notificationToken.userEmail,
      notificationToken: notificationToken.notificationToken,
      isPresto: notificationToken.isPresto,
      createdAt: notificationToken.createdAt,
    }
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): INotificationTokens => {
    const data = snapshot.data(options)
    return {
      userCode: data.userCode,
      userName: data.userName,
      userEmail: data.userEmail,
      notificationToken: data.notificationToken,
      isPresto: data.isPresto,
      createdAt: data.createdAt ? data.createdAt.toDate() : data.createdAt,
    }
  },
}

class NotificationFirestore {
  getNotificationTokenByCustomerId(customerId: string): NotificationData {
    const collectionRef = collection(db, notificationCollection).withConverter(
      notificationConverter
    )

    const queryData = query(collectionRef, where("userCode", "==", customerId.toString()))
    return getDocs(queryData).then((data) =>
      data.docs.length > 0 ? data.docs[0].data() : undefined
    )
  }
}

export const notificationFirestore = new NotificationFirestore()
