import axios from "axios"

import { apiConfig } from "../../config"
import ApiLogger from "./apiLogger"

interface ApiConfig {
  apiKey: string
  apiURL: string
  logLevel: string
}

let currentBrand : string;

class ApiBase {
  private client
  private logger
  private brand: string;
  constructor(config: typeof apiConfig) {
    const { apiURL, apiKey, logLevel } = config as ApiConfig
    this.brand = currentBrand;
    //console.log(apiURL)
    this.client = axios.create({
      baseURL: apiURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": apiKey,
        "brand": this.brand
      },
    })
    this.logger = new ApiLogger(false)
  }

  setCustomBrand = (brand: string) => {
    this.brand = brand;
    currentBrand = this.brand;
    this.updateHeaders();
  };

  private updateHeaders = () => {
    let headers = this.client.defaults.headers;

    headers['brand'] = this.brand;
    headers.common['brand'] = this.brand;

    this.client.defaults.headers = headers;
  };

  async get(endpoint: string, params?: object, brand?: string) {
    try {
      const response = await this.client.get(endpoint, { params })
      this.logger.trace(
        `GET ${endpoint} with params ${JSON.stringify(params)}: ${response.status} ${
          response.statusText
        }`
      )
      return response.data
    } catch (error) {
      if (error.response.status === 404) {
        return undefined
      }

      console.error(error)
      throw error
    }
  }

  async post(endpoint: string, data: object) {
    try {
      const response = await this.client.post(endpoint, data)
      this.logger.trace(
        `POST ${endpoint} with data ${JSON.stringify(data)}: ${response.status} ${
          response.statusText
        }`
      )
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async put(endpoint: string, data: object) {
    try {
      const response = await this.client.put(endpoint, data)
      this.logger.trace(
        `PUT ${endpoint} with data ${JSON.stringify(data)}: ${response.status} ${
          response.statusText
        }`
      )
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async patch(endpoint: string, data: object) {
    try {
      const response = await this.client.patch(endpoint, data)
      this.logger.trace(
        `PATCH ${endpoint} with data ${JSON.stringify(data)}: ${response.status} ${
          response.statusText
        }`
      )
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async delete(endpoint: string) {
    try {
      const response = await this.client.delete(endpoint)
      this.logger.trace(`DELETE ${endpoint}: ${response.status} ${response.statusText}`)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export default ApiBase
