import { t } from "i18next"
import { apiClient } from "src/api/base/apiClient"
import { notificationFirestore } from "src/firebase-services/firestore/notification-repository"
import { tokens } from "src/locales/tokens"
import { IChat } from "src/types/chat"
import {
  INotificationCreate,
  INotificationResponse,
  INotificationSubscribeResponse,
} from "src/types/notification"

const NOTIFICATION_TAG = "chat, admin, presto"
const NOTIFICATION_TYPE = "CHAT_NOTIFICATION"
const DISABLE_NOTIFICATION = true

class NotificationApi {
  async getNotificationsByTopicName(topicName: string): Promise<INotificationResponse> {
    return apiClient
      .get(`v1/donna/notification?notificationTopicName=${topicName}`)
      .then((response) => {
        return response as INotificationResponse
      })
  }

  async sendNotification(
    chat: IChat | undefined,
    message: string,
    chatCode: string
  ): Promise<void> {
    const notificationToken = await notificationFirestore.getNotificationTokenByCustomerId(
      chat?.customerId!
    )

    const notification: INotificationCreate = {
      notificationId: chatCode,
      notificationTag: NOTIFICATION_TAG,
      notificationType: NOTIFICATION_TYPE,
      notificationToken: notificationToken?.notificationToken!,
      notificationLanguage: t(tokens.notification.locale),
      message: message,
      title: this.buildNotificationTitle(chat),
      createdAt: new Date(),
      customerId: parseInt(chat?.customerId!),
      enableNotificationByTopic: false,
      disableNotificationTemplate: DISABLE_NOTIFICATION,
    }

    return apiClient.post("v1/donna/notification/create", notification)
  }

  async subscribeToTopicByName(
    topicName: string,
    token: string
  ): Promise<INotificationSubscribeResponse> {
    const subscribeBody = {
      notificationToken: token,
      notificationTopicName: topicName,
    }

    return apiClient
      .post(`v1/donna/notification/subscribe/create`, subscribeBody)
      .then((response) => {
        return response as INotificationSubscribeResponse
      })
  }

  private buildNotificationTitle(chat: IChat | undefined): string {
    if (typeof chat !== "undefined" && typeof chat.chatTitle !== "undefined") {
      return `${t(tokens.notification.title)} - ${chat?.chatTitle!}`
    }
    return t(tokens.notification.title)
  }
}

export const notificationApi = new NotificationApi()
