import { format } from 'date-fns'
import { apiClient } from "src/api/base/apiClient"
import Avatar from "src/libs/avatar"
import { CreateUserOutput, IMeta, IUser, IUserResponse, IUsers } from "src/types/user"
import { applyPagination } from "src/utils/apply-pagination"
import { applySort } from "src/utils/apply-sort"

type GetUsersRequest = {
  filters?: {
    query?: string
    queryType?: string
    id?: string
    isActive?: boolean;
    isCustormer?: boolean;
    isConcierge?: boolean;
    isStaff?: boolean;
    brand:string
  }
  page?: number
  rowsPerPage?: number
  searchBy?: string
  sortBy?: string
  sortDir?: "asc" | "desc"
}

type GetUsersResponse = Promise<{
  data: IUser[]
  count: number
}>



type GetUserRequest = {}

type GetUserResponse = Promise<IUser>

type GetIUserResponse = Promise<IUser>


class UsersApi {
  private avatarInstance = new Avatar();

  mapUser = (userMap: any): IUser => {
    const user: IUser = {
      id: userMap.id || '',
      avatar: userMap.avatar || '',
      email: userMap.email || '',
      name: userMap.fullName || '',
      gender: userMap.gender || '',
      phone: userMap.phoneNumber || '',
      userTypeId: userMap.userTypeId || '',
      userIdAccountType: userMap.userIdAccountType || '',
      userType: userMap.userType || '',
      status: userMap.isActive || false,
      lastLogin: userMap.lastLogin
      ? format(new Date(userMap.lastLogin), 'yyyy-MM-dd HH:mm:ss')
      : '',
      createAt: userMap.createdAt
        ? format(new Date(userMap.createdAt), 'yyyy-MM-dd HH:mm:ss')
        : '',
      updateAt: userMap.updatedAt
        ? new Date(userMap.updatedAt)
        : undefined,
      brand: userMap.brand};
    return user;
  };

  
  async getUsers(request: GetUsersRequest = {} , size: number = 100): GetUsersResponse {
    const { filters, page, rowsPerPage,searchBy, sortBy, sortDir } = request;
    let countPage = (page as number * rowsPerPage! as number + rowsPerPage! as number);
    let pageChoose = page as number;
    const currentPageMultiple = Math.ceil(countPage / 25); 

    if (pageChoose > 0) {
      pageChoose = currentPageMultiple;
    }

    let queryString = `v1/donna/user?Page=${pageChoose}&PageSize=${size}`;
    if (filters?.queryType && filters.queryType.trim() !== '') {
      queryString = queryString + `&${filters?.queryType}=${(filters?.query)}`;
    }

    if (typeof filters !== "undefined") {
      if(filters.brand == "PRE" || filters.brand == "PRO"){
        apiClient.setCustomBrand(filters.brand);
      } else{
        apiClient.setCustomBrand("PRE");
      }
    }

    const response = await apiClient.get(queryString);
    let users = response as IUsers[]
    let meta = response.meta as IMeta
    let data: IUser[] = response.users.map((userMap: any) => {
      let user = this.mapUser(userMap);
      
      return {
        id: user.id,
        avatar: this.avatarInstance.getAvatarTag(user.id, user.gender||'M'),
        email: user.email,
        gender:user.gender,
        name: user.name,
        userIdAccountType: user.userIdAccountType,
        userType: user.userType,
        createAt: user.createAt,
        lastLogin: user.lastLogin,
        status: user.status,
        updateAt: user.updatedAt ? new Date(user.updatedAt) : undefined,
        brand : {
          id: user.brand?.id,
          brand: user.brand?.brand,
          companyRegistrationNumber: user.brand?.companyRegistrationNumber,
          acronym: user.brand?.acronym,
        }  
      };
    });
  
    let count = meta.totalRecords;
  
    if (filters?.query && filters.query !== "") {
      data = data.filter((user) => {
        let queryMatched = false;
        const properties: ("email" | "name" | "userType" )[] = ["email", "name", "userType"];
  
        properties.forEach((property) => {
          if (user[property]?.toLowerCase().includes(filters.query!.toLowerCase())) {
            queryMatched = true;
          }
        });
  
        return queryMatched;
      });
      count = meta.totalRecords
    }
  
    if (filters?.isActive !== undefined) {
      data = data.filter((user) => user.status === false);
      count = data.length;
    }
  
    if (filters?.isCustormer !== undefined) {
      data = data.filter((user) => user.userType === 'customer');
      count = data.length;
    }
  
    if (filters?.isConcierge !== undefined) {
      data = data.filter((user) => user.userType === 'concierge');
      count = data.length;
    }

    if (filters?.isStaff !== undefined) {
      data = data.filter((user) => user.userType === 'staff_presto');
      count = data.length;
    }
  
    if (sortBy !== undefined && sortDir !== undefined) {
      data = applySort(data, sortBy, sortDir);
    }
  
    if (page !== undefined && rowsPerPage !== undefined) {
      data = applyPagination(data, pageChoose, rowsPerPage);
    }
    return {
      data,
      count,
    };
  }
   
  
  updateUser(userId: string, body: IUser): Promise<void> {
    if (typeof userId === "undefined" || typeof body === "undefined") {
      return Promise.resolve()
    }
    let user =  this.mapUserToUserResponse(body);
    return apiClient.patch(`v1/donna/user/${userId}/update`, user)
  }

  mapUserToUserResponse = (userMap: any): IUserResponse => {
    const user: IUserResponse = {
      userIdLinkedLogin: userMap.id,
      email: userMap.email || '',
      fullName: userMap.name || '',
      gender: userMap.gender || '',
      phoneNumber: userMap.phone || '',
      userTypeId: userMap.userTypeId || '',
      isActive: userMap.status || false,
      brand: userMap.brand || undefined
    };
    return user;
  };

  createUser(body: CreateUserOutput): Promise<void> {
    if (typeof body === "undefined") {
      return Promise.resolve()
    }

    return apiClient.post("v1/donna/user/create", body)
  }


  async getUsersbyId(userId: string): GetUsersResponse {
    
  const response = await  apiClient.get(`v1/donna/user/${userId}`);
  const dataArray = [response];
    let users = response as IUsers[]
    let meta = response.meta as IMeta
    let data: IUser[] = dataArray.map((userMap: any) => {
      let user = this.mapUser(userMap);
      
      return {
        id: user.id,
        avatar: this.avatarInstance.getAvatarTag(user.id, user.gender||'M'),
        email: user.email,
        gender:user.gender,
        name: user.name,
        phone: user.phone,
        userIdAccountType: user.userIdAccountType,
        userType: user.userType,
        userTypeId: user.userTypeId,
        lastLogin: user.lastLogin,
        createAt: user.createAt,
        status: user.status,
        updateAt: user.updatedAt ? new Date(user.updatedAt) : undefined,
        brand : user.brand
      };
    });
  
    let count = data.length;
  
    return {
      data,
      count,
    };
  }

}
  

export const usersApi = new UsersApi()
