import type { User as FirebaseUser } from "@firebase/auth"
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth"
import "firebase/messaging"
import PropTypes from "prop-types"
import type { FC, ReactNode } from "react"
import { createContext, useCallback, useEffect, useReducer } from "react"
import { usersApi } from "src/api/users"
import { firebaseConfig, notificationConfig } from "src/config"
import { noticationMessaging } from "src/firebase-services/messaging/notification-messaging"
import { firebaseApp } from "src/libs/firebase"
import type { IUser } from "src/types/user"
import { Issuer } from "src/utils/auth"

const auth = getAuth(firebaseApp)
let firebaseUser = auth.currentUser || null

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: IUser | null
}

enum ActionType {
  AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED",
}

type AuthStateChangedAction = {
  type: ActionType.AUTH_STATE_CHANGED
  payload: {
    isAuthenticated: boolean
    user: IUser | null
  }
}

type Action = AuthStateChangedAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const reducer = (state: State, action: Action): State => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }

  return state
}

export interface AuthContextType extends State {
  issuer: Issuer.Firebase
  sendPasswordResetEmail: (email: string) => Promise<any>
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>
  resetPasswordUserWithPassword: (
    password: string,
    currentPassword: string,
    email: string
  ) => Promise<any>
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>
  signInWithGoogle: () => Promise<any>
  signOut: () => Promise<void>
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.Firebase,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  resetPasswordUserWithPassword: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
})

interface AuthProviderProps {
  children: ReactNode
}

enum UserType {
  Customer = "customer",
  Concierge = "concierge",
  Staff_Presto = "staff_presto",
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  let userMap: IUser | null = null // Defina a variável 'mappedUser' aqui

  const handleNotificationPermissionAndSubscription = useCallback(async () => {
    // Check if the browser supports notifications
    if ("Notification" in window) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted")
            // You can now subscribe to topics or send messages using FCM.
          } else if (permission === "denied") {
            console.log("Notification permission denied")
            // Handle denied permission
          } else {
            console.log("Notification permission dismissed")
            // Handle dismissed permission (usually by asking again later)
          }
        })
        .catch((error) => {
          console.error("Error requesting notification permission:", error)
        })
    }

    await noticationMessaging.subscribeToTopicByName(notificationConfig.topicName!)
  }, [])

  const handleAuthStateChanged = useCallback(
    (user: FirebaseUser | null) => {
      if (user) {
        usersApi
          .getUsersbyId(user.uid)
          .then((userData) => {
            const userMap = userData.data[0]
            const isAuthenticated = userMap?.userType !== UserType.Customer

            const authenticatedUserData = isAuthenticated
              ? {
                  id: user.uid,
                  avatar: userMap.avatar || user.photoURL || undefined,
                  email: user.email || "anika.visser@devias.io",
                  name: userMap?.name || "",
                  plan: userMap?.userType || "",
                  status: userMap?.status,
                  userTypeId: userMap?.userTypeId,
                }
              : null

            dispatch({
              type: ActionType.AUTH_STATE_CHANGED,
              payload: {
                isAuthenticated,
                user: authenticatedUserData,
              },
            })
          })
          .then((_) => {
            handleNotificationPermissionAndSubscription()
          })
          .catch((error) => {
            console.error("Error fetching user data:", error)
            dispatch({
              type: ActionType.AUTH_STATE_CHANGED,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            })
          })
      } else {
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    },
    [dispatch]
  )

  useEffect(
    () => onAuthStateChanged(auth, handleAuthStateChanged),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const _signInWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<void> => {
      await signInWithEmailAndPassword(auth, email, password)
    },
    []
  )

  const signInWithGoogle = useCallback(async (): Promise<void> => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      hd: firebaseConfig.domain || "getpresto.app",
    })
    await signInWithPopup(auth, provider)
  }, [])

  const _createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<void> => {
      await createUserWithEmailAndPassword(auth, email, password)
    },
    []
  )

  const _sendPasswordResetEmail = useCallback(async (email: string): Promise<void> => {
    await sendPasswordResetEmail(auth, email)
  }, [])

  const _resetPasswordUserWithPassword = useCallback(
    async (password: string, currentPassword: string, email: string): Promise<void> => {
      try {
        await signInWithEmailAndPassword(auth, email, currentPassword)
        let firebaseUser = auth.currentUser || null
        if (firebaseUser !== null) {
          await updatePassword(firebaseUser, password)
        }
      } catch (error) {
        console.log(error)
      }
    },
    []
  )

  const _signOut = useCallback(async (): Promise<void> => {
    await signOut(auth)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.Firebase,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        resetPasswordUserWithPassword: _resetPasswordUserWithPassword,
        sendPasswordResetEmail: _sendPasswordResetEmail,
        signInWithGoogle,
        signOut: _signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const AuthConsumer = AuthContext.Consumer
