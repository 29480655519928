type Document = any

export function applyPagination<T = Document>(
  documents: T[],
  page: number,
  rowsPerPage: number
): T[] {
  let currentPage = parseInt(localStorage.getItem("currentPage") || "1", 10)
  if (currentPage != page && page > 1) {
    localStorage.setItem("restart", "true")
  }
  let restart = localStorage.getItem("restart") === "true"

  if (page === 0 || (currentPage != page && restart)) {
    localStorage.setItem("currentPage", "0")
    localStorage.setItem("endIndex", "0")
    localStorage.setItem("rowsPerPage", "0")
    localStorage.setItem("restart", "false")
  }

  let storedEndIndex = parseInt(localStorage.getItem("endIndex") || "0", 10)
  let endIndex = storedEndIndex || rowsPerPage

  let startIndex = page * rowsPerPage

  if (page === 0) {
    endIndex = startIndex + rowsPerPage
    if (endIndex === startIndex) {
      endIndex = rowsPerPage + rowsPerPage
    }
  } else {
    startIndex = endIndex - rowsPerPage
    if (startIndex == 0 && page <= 1) {
      startIndex = endIndex
    }
    if (endIndex === startIndex) {
      endIndex = endIndex + rowsPerPage
    }
  }
  const changePage = startIndex + rowsPerPage === endIndex

  const slicedDocuments = documents.slice(startIndex, endIndex)

  if (changePage) {
    endIndex += rowsPerPage
    localStorage.setItem("currentPage", page.toString())
    localStorage.setItem("endIndex", endIndex.toString())
    localStorage.setItem("rowsPerPage", rowsPerPage.toString())
  }

  return slicedDocuments
}

export function applyPaginationList<T = Document>(documents: T[], page: number, size: number): T[] {
  if (documents.length <= 0) {
    return []
  }

  let startIndex = page * size

  if (startIndex > documents.length) {
    startIndex = 0
  }

  let endIndex = startIndex + size

  if (endIndex > documents.length) {
    endIndex = documents.length
  }

  const currentPage = documents.slice(startIndex, endIndex)

  return currentPage
}
