import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import { noticationMessaging } from 'src/firebase-services/messaging/notification-messaging';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { messaging } from 'src/firebase-services/firestore-base';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import { INotification, INotificationFromFCM, INotificationResponse } from 'src/types/notification';
import { useMounted } from 'src/hooks/use-mounted';
import { notificationApi } from 'src/api/notification';
import { notificationConfig } from 'src/config';

/*const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationResponse[]>(initialNotifications);
  const unread = useMemo(
    (): number => {
      return notifications.reduce(
        (acc, notification) => acc + (notification.read ? 0 : 1),
        0
      );
    },
    [notifications]
  );

  const handleRemoveOne = useCallback(
    (notificationId: string): void => {
      setNotifications((prevState) => {
        return prevState.filter((notification) => notification.id !== notificationId);
      });
    },
    []
  );

  const handleMarkAllAsRead = useCallback(
    (): void => {
      setNotifications((prevState) => {
        return prevState.map((notification) => ({
          ...notification,
          read: true
        }));
      });
    },
    []
  );

  return {
    handleMarkAllAsRead,
    handleRemoveOne,
    notifications,
    unread
  };
};*/

const useNotifications = () => {
  const isMounted = useMounted()
  const [notificationsFromApi, setNotificationsFromApi] = useState<INotificationResponse>({
    notifications: [] as INotification[],
    meta: {
      totalRecords: 0,
      totalPages: 0
    }
  });

  const handleRequestsGet = useCallback(async () => {
    try {
      const response = await notificationApi.getNotificationsByTopicName(notificationConfig.topicName!)

      if (isMounted()) {
        setNotificationsFromApi({
          notifications: response.notifications,
          meta: response.meta
        })
      }
    } catch (err) {
      console.error(err)
    }
  }, [isMounted])

  useEffect(
    () => {
      handleRequestsGet()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    notificationsFromApi,
  };
};



export const useNotification = (payload: MessagePayload) => {
  const [notificationCountInternal, setNotificationCountInternal] = useState<number>(0)
  const [notificationsInternal, setNotificationsInternal] = useState<INotification[]>([] as INotification[])
  
  const numberOfNotifications = notificationCountInternal + 1;
  setNotificationCountInternal(numberOfNotifications);

  const notificationsActual = notificationsInternal
  const notificationFromFCM = payload as unknown as INotificationFromFCM
  const newNotification: INotification = {
    id: notificationFromFCM.data.id,
    data: {
      title: notificationFromFCM.notification.title,
      description: notificationFromFCM.notification.body,
      notificationType: notificationFromFCM.data.type,
    },
    createdAt: notificationFromFCM.data.createdAt,
  }
  const notificationsNew: INotification[] = [newNotification, ...notificationsActual];
  setNotificationsInternal(notificationsNew)

/*  console.log('########################----START-2')
  console.log(notificationsFromApi)
  console.log('########################----END')*/

  return {
    notificationCountInternal,
    ...notificationsInternal
  }
}


interface NotificationPayloadProps {
  data?: MessagePayload | undefined;
  open: boolean;
}

export const NotificationsButton: FC = () => {
  const { t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  //const { handleRemoveOne, handleMarkAllAsRead, notifications, unread } = useNotifications();
  const {notificationsFromApi} = useNotifications();
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const [notifications, setNotifications] = useState<INotification[]>([] as INotification[])

    // To store notification data from firebase
    const [notificationPayload, setNotificationPayload] = useState<
    (NotificationPayloadProps | undefined)[]
  >([]);
  
    // This is self invoking function that listen of the notification
    const onMessageListener = (async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, (payload: MessagePayload) => {

          const numberOfNotifications = notificationCount + 1;
          setNotificationCount(numberOfNotifications);

          const notificationsActual = notifications
          const notificationFromFCM = payload as unknown as INotificationFromFCM
          const newNotification: INotification = {
            id: notificationFromFCM.data.id,
            data: {
              title: notificationFromFCM.notification.title,
              description: notificationFromFCM.notification.body,
              notificationType: notificationFromFCM.data.type,
              orderId: notificationFromFCM.data.orderId
            },
            createdAt: notificationFromFCM.data.createdAt,
          }
          const notificationsNew: INotification[] = [newNotification, ...notificationsActual];
          setNotifications(notificationsNew)

          console.log('########################----START-2')
          console.log(payload)
          console.log('########################----END')

          setNotificationPayload([{ data: payload, open: true }]);
          setTimeout(() => setNotificationPayload([{ open: false }]), 6000);
        });
      }
    })(); 
  
    const handleGetFirebaseToken = () => {
      noticationMessaging.getFirebaseToken().then((firebaseToken: string | undefined) => {
        if (firebaseToken) {
          console.log(firebaseToken);
        }
      });
    };

    const handleMarkAllAsRead = useCallback(
      (): void => {
        setNotificationCount(0)
      },
      []
    );

    useEffect(() => {
      if(notificationsFromApi.notifications.length > 0) {
        setNotifications(notificationsFromApi.notifications)
        console.log('########################----START-3')
        console.log(notificationsFromApi)
        console.log('########################----END')
      }
    }, [notificationsFromApi])

     // Need this handle FCM token generation when a user manually blocks or allows notification
     /*useEffect(() => {
      if (
        "Notification" in window &&
        window.Notification?.permission === "granted"
      ) {
        handleGetFirebaseToken();
      }
    }, []);*/ 

  return (
    <>
      <Tooltip title={t(tokens.notification.dashboard.title)}>
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notificationCount}
          >
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        onClose={popover.handleClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        //onRemoveOne={handleRemoveOne}
        open={popover.open}
      />
    </>
  );
};
