class Avatar {
    private avatarCollection = [
        { avatar: '/assets/avatars/avatar-alcides-antonio.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-anika-visser.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-cao-yu.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-carson-darrin.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-chinasa-neo.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-fran-perez.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-iulia-albu.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-jane-rotanson.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-jie-yan-song.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-marcus-finn.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-miron-vitold.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-nasimiyu-danai.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-neha-punita.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-omar-darboe.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-penjani-inyene.png', gender: 'M' },
        { avatar: '/assets/avatars/avatar-seo-hyeon-ji.png', gender: 'F' },
        { avatar: '/assets/avatars/avatar-siegbert-gottfried.png', gender: 'M' }
    ];
  
    private defaultAvatar = '/assets/avatars/default-avatar.png'; // Coloque aqui o caminho para o avatar padrão
  
    getAvatarTag(id: string, gender?: string): string {
      const localStorageKey = `userAvatar_${id}`;
  
      // Obtém o avatar escolhido do localStorage, se estiver armazenado
      const storedAvatar = localStorage.getItem(localStorageKey);
  
      // Se o avatar estiver armazenado e não tiver expirado, usa o avatar armazenado
      if (storedAvatar) {
        const storedData = JSON.parse(storedAvatar);
        const { avatar, timestamp } = storedData;
  
        // Verifica se o avatar foi salvo há menos de 1 dia (em milissegundos)
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        const currentTime = new Date().getTime();
        if (currentTime - timestamp < oneDayInMilliseconds) {
          return avatar;
        }
      }
  
         // Find the available avatars for the given gender
    const availableAvatars = this.avatarCollection.filter(avatar => avatar.gender === gender);

    // Get the last selected avatar index from localStorage
    let lastAvatarIndex = localStorage.getItem(`lastAvatarIndex_${localStorageKey}`);
    if (!lastAvatarIndex) {
      // If no last avatar index stored, initialize it to -1
      lastAvatarIndex = '-1';
    }

    // Generate a random index for the new avatar, excluding the last selected one
    let randomIndex = Math.floor(Math.random() * availableAvatars.length);
    while (randomIndex.toString() === lastAvatarIndex) {
      randomIndex = Math.floor(Math.random() * availableAvatars.length);
    }

    const selectedAvatar = availableAvatars[randomIndex];
    const avatarPath = selectedAvatar ? selectedAvatar.avatar : this.defaultAvatar;

    // Salva o avatar escolhido e o seu índice no localStorage com o timestamp atual
    const dataToStore = JSON.stringify({
      avatar: avatarPath,
      timestamp: new Date().getTime(),
      index: randomIndex.toString(),
    });
    localStorage.setItem(localStorageKey, dataToStore);

  
  
      return avatarPath;
    }
  }
  
  export default Avatar;
  